import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import "./Home.css";

import coin1 from "../assets/coins/valor1.png";
import coin5 from "../assets/coins/valor5.png";
import coin10 from "../assets/coins/valor10.png";
import coin100 from "../assets/coins/valor100.png";


const Admin = () => {

    const [mockUsuario] = useState({
        nome: "Jean",
        moedas: {
          moeda1: 0,
          moeda5: 0,
          moeda10: 0,
          moeda100: 0,
        },
        saldoTotal: 0,
        data: "2023-10-18",
        valorMoeda: 0,
        transacoes: [
          {
            data: "10/02/23",
            valor: 5,
            remetente: "Matheus Santos - Gerente Geral",
            secao: "Your Dry Value Here",
            mandamento: "Your Commandment Value Here",
            unidade: "Your Unit Value Here",
          },
          {
            data: "20/05/23",
            valor: 1,
            remetente: "Gustavo Henrique - Líder de área",
            secao: "Your Dry Value Here",
            mandamento: "Your Commandment Value Here",
            unidade: "Your Unit Value Here",
          },
          {
            data: "12/07/23",
            valor: 1,
            remetente: "Juliana Tierri - Líder de área",
            secao: "Your Dry Value Here",
            mandamento: "Your Commandment Value Here",
            unidade: "Your Unit Value Here",
          },
          {
            data: "14/08/23",
            valor: 10,
            remetente: "Pedro Silva - Holding",
            secao: "Your Dry Value Here",
            mandamento: "Your Commandment Value Here",
            unidade: "Your Unit Value Here",
          },
          {
            data: "16/11/23",
            valor: 100,
            remetente: "Carol Marques - Holding",
            secao: "Your Dry Value Here",
            mandamento: "Your Commandment Value Here",
            unidade: "Your Unit Value Here",
          },
        ],
        remetente: "",
      });
      const [sentCoinsData, setSentCoinsData] = useState(mockUsuario.transacoes);
    
      const [receivedItems, setReceivedItems] = useState([]);
    
      const [updatedBalance, setUpdatedBalance] = useState(0);
    
    
      const calcularSaldoTotal = (moedas) => {
        const { moeda1, moeda5, moeda10, moeda100 } = moedas;
        return moeda1 * 1 + moeda5 * 5 + moeda10 * 10 + moeda100 * 100;
      };
     
      useEffect(() => {
        const totalBalance = calcularSaldoTotal(mockUsuario.moedas);
        let updatedBalanceValue = totalBalance;
    
        if (receivedItems && receivedItems.length > 0) {
          receivedItems.forEach((receivedItem) => {
            updatedBalanceValue -= receivedItem.price;
          });
        }
        setUpdatedBalance(updatedBalanceValue);
      }, [receivedItems, mockUsuario.moedas]);
    
      useEffect(() => {
        const savedUserData = localStorage.getItem("userData");
        if (savedUserData) {
          const userData = JSON.parse(savedUserData);
          setSentCoinsData(userData.transacoes);
    
          if (userData.receivedItems) {
            setReceivedItems((prevItems) => [...prevItems, ...userData.receivedItems]);
          }
    
        }
      }, []);

    return (
        <div className="home-container">
            <div className="welcome-bar">
                <h1>Área do Administrador</h1>
                <Link to="/excluir-moeda">
                  <button className="button-coin-form">Excluir Moeda</button>
                </Link>
                <Link to="/atribuir-moeda">
                  <button className="button-coin-form">Atribuir Moeda</button>
                </Link>
            </div>
            <div className="boards">
                <div className="coin-amount-wrapper">
                <div className="coin-amount-column-wrapper">
                    <div className="coin-display">
                    <img src={coin1} alt="Coin 1" />
                    <h4>{}</h4>
                    </div>
                    <div className="coin-display">
                    <img src={coin5} alt="Coin 5" />
                    <h4>{}</h4>
                    </div>
                    <div className="coin-display">
                    <img src={coin10} alt="Coin 10" />
                    <h4>{}</h4>
                    </div>
                    <div className="coin-display">
                    <img src={coin100} alt="Coin 100" />
                    <h4>{}</h4>
                    </div>
                </div>
                <h3>
                    Saldo Total: <span>${parseInt(updatedBalance.toString())}</span>
                </h3>
                </div>

                <table className="last-transaction">
                <thead>
                    <tr className="last-transaction-header">
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Remetente</th>
                    </tr>
                </thead>
                <tbody>
                    {sentCoinsData
                    ?.slice(Math.max(sentCoinsData?.length - 5, 0))

                    .map((sentCoin, index) => (
                        <tr key={index}>
                        <td>{/** {new Date().toLocaleDateString()} */}</td>
                        <td className="value">
                            {/** <img src={''} alt={''}/> */}
                            <td>{}</td>
                        </td>
                        <td>{}</td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
            <h2 className="main-section-title">Ultimas transações</h2>
            <div className="sent-coins">
                <table className="sent-coins-table">
                <thead>
                    <tr>
                    <th>Moeda</th>
                    <th>Data</th>
                    <th>Unidade</th>
                    <th>Setor</th>
                    <th>Remetente</th>
                    <th>Mandamento</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={''}>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                    </tr>
                </tbody>
                </table>
            </div>

            <h2 className="main-section-title">Prêmios convertidos</h2>
            <div className="received-items">
                {updatedBalance < 0 ? (
                <div className="insufficient-balance-message">
                    <p>Saldo Insuficiente!</p>
                </div>
                ) : (
                <table className="received-items-table">
                    <thead>
                    <tr>
                        <th>Nome do Item</th>
                        <th>Quantidade</th>
                        <th>Preço</th>
                        <th>Data</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr key={''}>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                        </tr>
                    </tbody>
                </table>
                )}
            </div>
        </div>
    )
}

export default Admin;