import { useRef, useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './Login.css';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';

const LOGIN_URL = '/sessions';

const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL, 
                JSON.stringify({
                    personal_document: user,
                    password: pwd
            }), {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            });

            const accessToken = response?.data?.token;
            const role = response?.data?.role;

            localStorage.setItem('token', response?.data?.token);
            localStorage.setItem('userId', response?.data?.id)

            setAuth({user, pwd, role, accessToken});

            setUser('');
            setPwd('');
            navigate(from, { replace: true});
        } catch (err) {
            if(!err?.response) {
                console.log(err)
                setErrMsg('Sem resposta do servidor');
            } else if (err.response?.status === 400) {
                setErrMsg("Um ou mais dos campos está errado ou em branco");
            } else if (err.response?.status === 401) {
                setErrMsg('Não autorizado');
            } else {
                setErrMsg('Falha em logar');
            }
        }
    }

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Banco da Felicidade</h2>
                <p style={{ position: "relative", top: "-28px", color: "Grey" }}>
                Faça o login para continuar.
                </p>
                <section>
                    <p
                        ref={errRef}
                        className={errMsg ? "errmsg" : "offscren"}
                        aria-live="assertive"
                        
                    >{errMsg}</p>
                    <form className="login-form" onSubmit={handleSubmit}>

                        <label className="login-label">
                            <input
                                className="login-input"
                                type="number"
                                id="identification"
                                ref={userRef}
                                placeholder="Número do CPF"
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />
                        </label>
                        
                        <label className="login-label">
                            <input
                                className="login-input"
                                placeholder="Senha"
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                        </label>
                        <button className="login-butom">Entrar</button>
                    </form>
                </section>
            </div>
            <div className="login-text">
                <h2 className='login-title'>Banco da Felicidade</h2>
                <p className="text">
                    Um programa para reconhecer os emocionadores que estão praticando a nossa cultura (10 Mandamentos do Emocionador) por meio da entrega de MOEDAS SORRISO. Elas podem ser trocadas por prêmios no nosso Catálogo de Produtos.
                </p>
            </div>
        </div>
    )
}

export default Login;
