import { useLocation, Outlet } from "react-router-dom"

import { useEffect, useState } from "react";

import Navbar from "./reusable/Navbar";
import Footer from "./reusable/Footer";

const Layout = () => {
    const location = useLocation();
    const [hideNavFooter, setHideNavFooter] = useState(false);
    
    useEffect(() => {
        if (
          location.pathname === "/login" ||
          location.pathname === "/registerpage"
        ) {
          setHideNavFooter(true);
        } else {
          setHideNavFooter(false);
        }
    }, [location]);

    return(
        <>
            {!hideNavFooter && <Navbar/>}
            <main className="App">
                <Outlet/>
            </main>
            {!hideNavFooter && <Footer/>}
        </>
    )
}

export default Layout;
