import React, { useEffect } from 'react'

import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import imgCoin1 from "../../assets/coins/valor1.png";
import imgCoin5 from "../../assets/coins/valor5.png";
import imgCoin10 from "../../assets/coins/valor10.png";
import imgCoin50 from "../../assets/coins/valor50.png";
import imgCoin100 from "../../assets/coins/valor100.png";

import imgForm from "../../assets/Form.svg";
import imgForm2 from "../../assets/Form2.svg";
import imgForm3 from "../../assets/Form3.svg";
import imgForm4 from "../../assets/Form4.svg";

import { useNavigate } from 'react-router-dom';

import axios from "../../api/axios";

//legacy

import "../CoinForm.css";
import "react-datepicker/dist/react-datepicker.css";

import "./SendCoin.css"

//

const COLLABORATORS_URL = '/collaborators';

const steps = [
    {
        id: 'Colaborator',
        title: 'Emocionador'
    },
    {
        id: 'Attribution',
        title: 'Atribuição'
    },
    {
        id: 'Commandment',
        title: 'Mandamento'
    },
    {
        id: 'Confirmation',
        title: 'Confirmação'
    }
]

const SendCoin = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isThisAraxa, setIsThisAraxa] = useState(false);
    const [collaboratorsList, setCollaboratorsList] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        location: '',
        department: '',
        colaborator: '',
        coin: 0,
        commandment: '',
        description: '',
        date: 'not implemented yet',
        senderId: localStorage.userId,
        receiverId: 0
    });

    useEffect(() => {
        if (formValues.location === "Araxá") {
            setIsThisAraxa(true);
        } else {
            setIsThisAraxa(false);
        }
    }, [formValues.location]);
    
    function handleNext() {
        setCurrentStep((prevState) => prevState + 1);
    }

    function handlePrevious() {
        setCurrentStep((prevState) => prevState - 1);
    }
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'patch',
                    url: COLLABORATORS_URL,
                    data: {
                        job_location: formValues.location,
                        job_department: formValues.department
                    },
                    headers: {
                        'Authorization' : `Bearer ${localStorage.token}`
                    },
                    withCredentials: true
                })

                setCollaboratorsList(Object.values(response.data))
            } catch (error) {
                console.log("Erro ao buscar dados da API", error);
            }
        }

        fetchData();
    }, [formValues.location, formValues.department])

    function handleInputChange(event) {
        const { name, value } = event.target;

        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        console.log("Form sent...", formValues);
        navigate('/'); //Add loader here
    }

    function collaboratorsPicked(selectedCollaborator) {
        console.log('Selecionou o emocionador');

        setFormValues((data) => ({
            ...data,
            colaborator: selectedCollaborator.name,
            receiverId: selectedCollaborator.id
        }))
        console.log(selectedCollaborator.id);

    }

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className='form-box'>
                <div className="container-itens">
                    {steps[currentStep].id === 'Colaborator' && (
                        <>
                            <img className="img-top" src={imgForm} alt="" />
                            <div className='itens-form'>
                                <h2 className='container-title'>{steps[currentStep].title}</h2>
                                <section>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-helper-label">Unidade</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={formValues.location}
                                            name="location"
                                            label="Unidade"
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="">
                                                <em>Selecione unidade</em>
                                            </MenuItem>
                                            <MenuItem value={'Caeté'}>Caeté</MenuItem>
                                            <MenuItem value={'Atibaia'}>Atibáia</MenuItem>
                                            <MenuItem value={'Alexânia'}>Alexânia</MenuItem>
                                            <MenuItem value={'Alegro'}>Alegro</MenuItem>
                                            <MenuItem value={'Araxá'}>Araxá</MenuItem>
                                            <MenuItem value={'Holding'}>Holding</MenuItem>
                                        </Select>
                                        <FormHelperText>With label + helper text</FormHelperText>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-helper-label">Departamento</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={formValues.department}
                                            name="department"
                                            label="Departamento"
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="">
                                                <em>Selecione um departamento</em>
                                            </MenuItem>
                                            <MenuItem value={'Limpeza'}>Limpeza</MenuItem>
                                            <MenuItem value={'Governança'}>Governança</MenuItem>
                                            <MenuItem value={'TI'}>TI</MenuItem>
                                            <MenuItem value={'Manutenção'}>Manutenção</MenuItem>
                                        </Select>
                                        <FormHelperText>With label + helper text</FormHelperText>
                                    </FormControl>
                                    <FormControl>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            name="colaborator"
                                            noOptionsText={'Nenhum emocionador encontrado'}
                                            options={collaboratorsList}
                                            onChange={(event, newValue) => collaboratorsPicked(newValue)}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Emocionador"/>}
                                            getOptionLabel={(option) => option.name || ""}
                                        />
                                    </FormControl>
                                    {console.log(collaboratorsList)}
                                    {console.log(selectedOption)}
                                </section>
                            </div>
                        </>
                    )}
                    {steps[currentStep].id === 'Attribution' && (
                        <>
                            <img className="img-top" src={imgForm2} alt="" />
                            <section>
                                <label className="radio-container">
                                    <input
                                        type="radio"
                                        value="1"
                                        name="coin"
                                        onClick={handleInputChange}
                                    />
                                    <img className='coin-img' src={imgCoin1} alt="Moeda de valor 1" />
                                </label>
                                <label className="radio-container">
                                    <input
                                        type="radio"
                                        value="5"
                                        name="coin"
                                        onClick={handleInputChange}
                                    />
                                    <img className='coin-img' src={imgCoin5} alt="Moeda de valor 5" />
                                </label>
                                <label className="radio-container">
                                    <input
                                        type="radio"
                                        value="10"
                                        name="coin"
                                        onClick={handleInputChange}
                                    />
                                    <img className='coin-img' src={imgCoin10} alt="Moeda de valor 10" />
                                </label>
                                <label className="radio-container">
                                    <input
                                        type="radio"
                                        value="50"
                                        name="coin"
                                        onClick={handleInputChange}
                                    />
                                    <img className='coin-img' src={imgCoin50} alt="Moeda de valor 50" />
                                </label>
                                <label className="radio-container">
                                    <input
                                        type="radio"
                                        value="100"
                                        name="coin"
                                        onClick={handleInputChange}
                                    />
                                    <img className='coin-img' src={imgCoin100} alt="Moeda de valor 100" />
                                </label>
                            </section>
                        </>
                    )}

                    {steps[currentStep].id === 'Commandment' && (
                        <>
                            <img className="img-top" src={imgForm3} alt="" />
                            <section>
                                <FormControl sx={{ m: 1, minWidth: 400 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Mandamento</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={formValues.commandment}
                                        name="commandment"
                                        label="Mandamento"
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="">
                                            <em>Selecione unidade</em>
                                        </MenuItem>
                                        <MenuItem value={'1 - Rir'}>1 - Rir</MenuItem>
                                        <MenuItem value={'2 - Cumprimente sempre'}>2 -Cumprimente sempre</MenuItem>
                                        <MenuItem value={'3 - Converse com o hóspede'}>3 - Converse com o hóspede</MenuItem>
                                        <MenuItem value={'4 - O cliente é o Rei'}>4 - O cliente é o Rei</MenuItem>
                                        <MenuItem value={'5 - Seja colaborativo'}>5 - Seja colaborativo</MenuItem>
                                        <MenuItem value={'6 - Atenção total, cara alegre e rápido'}>6 - Atenção total, cara alegre e rápido</MenuItem>
                                        <MenuItem value={'7 - Tauá é uma empresa de oportunidades'}>7 - Tauá é uma empresa de oportunidades</MenuItem>

                                        {isThisAraxa
                                            ? <MenuItem value={'8 - Tenha atenção aos detalhes'}>8 - Tenha atenção aos detalhes</MenuItem>
                                            : <MenuItem value={'8 - Cante a música Amigos para sempre'}>8 - Cante a música Amigos para sempre</MenuItem>
                                        }

                                        <MenuItem value={'9 - Trabalhe em prol do resultado'}>9 - Trabalhe em prol do resultado</MenuItem>
                                        <MenuItem value={'10 - Seja Feliz'}>10 - Seja Feliz</MenuItem>
                                    </Select>
                                </FormControl>

                                <div>
                                    <input
                                        type="text"
                                        placeholder="Descrição"
                                        name="description"
                                        onChange={handleInputChange}
                                        value={formValues.description}
                                    />
                                </div>
                            </section>
                        </>
                    )}

                    {steps[currentStep].id === 'Confirmation' && (
                        <img className="img-top" src={imgForm4} alt="" />
                    )}

                    <div className="box-final">
                        <div className="info-final">
                            <div className="colum1">
                                <p style={{ fontSize: "20px", textAlign: "justify" }}>
                                    Emocionador: {formValues.colaborator}
                                </p>
                                <p style={{ fontSize: "20px", textAlign: "justify" }}>
                                    Valor: $ {formValues.coin}
                                </p>
                            </div>
                            <div className="colum2">
                                <p className="final-respostas">Unidade: {formValues.location}</p>
                                <p className="final-respostas">Departamento: {formValues.department}</p>
                                <p className="final-respostas">
                                    Mandamento: {formValues.commandment}
                                </p>
                                <p className="final-respostas">
                                    Descrição da emoção: {formValues.description}
                                </p>
                            </div>
                        </div>
                        {console.log(formValues)}
                    </div>

                    <div className='button-nextprevew'>
                        {currentStep !== 0 && (
                            <button className="button next form-butomVoltar" type="button" onClick={handlePrevious}>Voltar</button>
                        )}

                        {currentStep < steps.length - 1 && (
                            <button className="button next form-butom form-button-next" type="button" onClick={handleNext}>Avançar</button>
                        )}

                        {currentStep === steps.length - 1 && (
                            <>
                                <button className="button submit form-button-next" type="submit">
                                    Enviar
                                </button>
                                
                            </>
                        )}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SendCoin;
