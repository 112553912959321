import { Link } from 'react-router-dom'

const LinkPage = () => {
    return(
        <section>
            <h1>Links</h1>
            <ul>
                <li><Link to='/login'>Login</Link></li>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/manager'>Manager</Link></li>
                <li><Link to='/admin'>Admin</Link></li>
                <li><Link to='/unauthorized'>Unauthorized</Link></li>
            </ul>
        </section>
    )
}

export default LinkPage;
