import { useLocation, Navigate, Outlet} from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    const futureRoles = [auth?.role]; //We will move from single role to multiple roles in the next month.

    return (
        futureRoles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/nao-autorizado" state={{from: location}} replace />
                : <Navigate to="/login" state={{from: location}} replace />
    );
}

export default RequireAuth;
