import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../../assets/logo-taua-holding.jpeg";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-footer">
        <div className="logo-section">
          <img src={Logo} alt="Logo" />
        </div>

        <div className="info-section">
          <p>O Grupo Tauá</p>
          <p>Blog </p>
          <p>Denúncias</p>
          <p>Sustentabilidade</p>
          <p>FAQ</p>
        </div>
        <div className="contact-section">
          <p className="contact-heading">Contato</p>
          <div className="phone-wrapper">
            <div className="phone-number">
              <span>Caeté</span><span>(31) 3651-7856</span>
            </div>
            <div className="phone-number">
              <span>Atibaia</span><span>(11) 4416-5080</span>
            </div>
            <div className="phone-number">
              <span>Araxá</span><span>(34) 9149-0841</span>
            </div>
            <div className="phone-number">
              <span>Alexânia</span><span>(62) 3602-1272</span>
            </div>
            <div className="phone-number">
              <span>Holding</span><span>(31) 3265-2621</span>
            </div>
            <div className="phone-number">
              <span>Alegro</span><span>(11) 3185-0509</span>
            </div>
          </div>
          <div className="social-section">
            <a href="https://www.youtube.com/user/HotelTaua">
              <FontAwesomeIcon icon={faYoutube} className="social-icon" />
            </a>
            <a href="https://www.instagram.com/tauaresorts/">
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a>
            <a href="https://www.facebook.com/TauaResorts">
              <FontAwesomeIcon icon={faFacebook} className="social-icon" />
            </a>
            <a href="URL_DO_WHATSAPP">
              <FontAwesomeIcon icon={faWhatsapp} className="social-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright-section">
        <p>© Copyright 2021. Grupo Tauá Hoteis</p>
        <a href="https://tauaresorts.com.br/politica-de-privacidade/">
          <p className="privacity">Política de Privacidade</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
